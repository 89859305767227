<template>
  <b-row class="d-flex justify-content-center">
    <b-col sm="8" md="5">
      <div class="pt-5">
        <select-plan
          :screen="screen"
          :accountType="accountType"
          :planType="planType"
        />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import SelectPlan from '@/components/App/User/SelectPlan/SelectPlan.vue';
import {PLAN_TEMPLATE_SCREEN} from '@/components/App/User/SelectPlan/planSelectionScreensNames';

export default {
  components: {
    SelectPlan,
  },
  data: function() {
    const {accountType, planType} = this.$route.params;
    return {
      accountType,
      planType,
    };
  },
  computed: {
    screen: function() {
      return this.accountType && this.planType && PLAN_TEMPLATE_SCREEN;
    },
  },
};
</script>
